import React from "react"

import pam from "../images/pam-banner.jpg"
// IMAGEN DEL banner

//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import '../components/font-awesome';
//import { Accordion, Card } from "react-bootstrap"
//import ReactPlayer from "react-player"

import Layout from "../components/layout"
import SEO from "../components/seo"
//import News from "../components/news"
import CourseForm from "../components/course-form"

const webcentrix={
    Level:'', 
    academicUnit: 'ICDA', 
    career: 'programas-internacionales'
}



const progInternacionales = ({ data }) => (
    <Layout>
        <SEO title="Programas Internacionales - ICDA" />
        <section className="mb-4">
            {/* Banner y Titulo */}
            <div className="w-100 d-flex bg-light inst-img mb-4" style={{ backgroundImage: `url(${pam})` }}>
                <div className="w-100 flex-grow-1 d-flex flex-column align-items-center justify-content-center text-center text-white p-5 background">
                    <h2 className="mb-4 fw-bold text-uppercase title">
                        Programas Internacionales
                    </h2>
                </div>
            </div>
            <div className="container">
                {/* <div className="my-5">
                    <h4 className="font-weight-bold">
                        Titulo
                    </h4>
                    <p>
                        Subtitulo: Los programas a medida son propuestas llevadas a cabo por nuestra área de capacitación in company, diseñadas para dar respuesta a demandas específicas de generación, desarrollo y gestión del talento en una organización, ya sea ésta privada, pública o de la sociedad civil.
                    </p>
                </div> */}
                {/* <div className="my-5">
                    <h4 className="font-weight-bold">
                        MODALIDADES
                    </h4>
                    <p>
                        Dependiendo de los requermientos particulares de desarrollo de talento de cada organización, los prorgramas de capacitación a medida pueden llevarse a cabo a través de alguna de las siguientes modalidades:
                    </p>
                    <div className="mb-3 bg-light px-4 py-2">
                        <div className="my-2 py-2 border-bottom">
                            <h6 className="text-sec mb-2">
                                UNIVERSIDAD CORPORATIVA
                            </h6>
                            <p>
                                Se concibe como un proceso de educación continua a través del cual las empresas gestionan el conocimiento, integran, promueven y garantizan una formación estratégica para sus recursos humanos. Cada año esta capacitación se va adaptando a la realidad vigente de la organización que la realiza.
                            </p>
                        </div>
                        <div className="my-2 py-2 border-bottom">
                            <h6 className="text-sec mb-2">
                                PROGRAMAS EJECUTIVOS
                            </h6>
                            <p>
                                Propuestas codiseñadas entre el ICDA y la organización, que permiten en poco tiempo desarrollar habilidades y competencias específicas en alguna de las áreas funcionales de la gestión organizacional: Marketing, Finanzas, Recursos Humanos, Logística, Responsabilidad Social, Liderazgo, entre otras
                            </p>
                        </div>
                        <div className="my-2 py-2 border-bottom">
                            <h6 className="text-sec mb-2">
                                CURSOS DE POSGRADO
                            </h6>
                            <p>
                                Son programas de mediana duración para colaboradores que cuenten con formación de grado previa. La certificación del ICDA es acompañada por un legajo UCC.
                            </p>
                        </div>
                        <div className="my-2 py-2 border-bottom">
                            <h6 className="text-sec mb-2">
                                ENTRENAMIENTOS INTENSIVOS
                            </h6>
                            <p>
                                Talleres de corta duración concebidos en un ambiente participativo y de comunicación abierta, con dinámicas prácticas para alcanzar los objetivos de forma inmediata, trabajar y resolver situaciones específicas.
                            </p>
                        </div>
                        <div className="my-2 py-2 border-bottom">
                            <h6 className="text-sec mb-2">
                                OUTDOORS EXPERIENCIALES
                            </h6>
                            <p>
                                Jornadas de trabajo al aire libre, con actividades lúdicas y de simulación coordinadas por facilitadores especializados en comunicación, trabajo en equipo y liderazgo.
                            </p>
                        </div>
                        <div className="my-2 py-2">
                            <h6 className="text-sec mb-2">
                                JORNADAS ESPECIALIZADAS
                            </h6>
                            <p>
                                Seminarios, conferencias o desayunos de trabajo diseñados para un área o grupo de personas determinado de la organización. Estas actividades están a cargo de reconocidos docentes y consultores con expertise en diferentes temáticas de la realidad actual.
                            </p>
                        </div>
                    </div>
                </div> */}
                {/* video */}
                {/* <div className="container col-12 col-md-9 w-70 h-10 d-flex align-items-center justify-content-between">
                    <div className="embed-responsive embed-responsive-16by9 mb-5 container">
                        <ReactPlayer
                            url={"https://www.youtube.com/embed/RZQhdTTDqOw?autoplay=0&mute=0&controls=1&origin=https%3A%2F%2Fwww.ucc.edu.ar&playsinline=1&showinfo=0&rel=0&iv_load_policy=3&modestbranding=1&enablejsapi=1&widgetid=1"}
                            playing={true}
                            muted={true}
                            loop={true}
                            className="fembed-responsive-item"
                            width="100%"
                            height="100%"
                        />
                    </div>
                </div> */}
                <div className="my-5">
                    <CourseForm webcentrix={webcentrix}/>
                </div>
            </div>
        </section>
    </Layout>
)


export default progInternacionales

