import React, { useRef, useState } from "react"
import { Button, Col, Row, Alert } from "react-bootstrap"
import { Formik, Form, Field, ErrorMessage } from "formik"
import axios from "axios"
import ReactMarkdown from "react-markdown"

const CourseForm = ({ webcentrix, description }) => {
  const { level, academicUnit, career, agent } = webcentrix
  const [showMessage, setShowMessage] = useState(false)

  const btn_scrollMessage = useRef(null)
  const btn_submit = useRef(null)

  const executeScroll = () =>
    btn_scrollMessage.current.scrollIntoView({
      block: "center",
      behavior: "smooth",
    })

  return (
    <>
      <Formik
        initialValues={{
          agent,
          name: "",
          email: "",
          phone: "",
          institution: "",
          academicUnit,
          level,
          career,
        }}
        onSubmit={(values, actions) => {
          btn_submit.current.classList.add("loading")
          axios
            .post("https://strapi.uccor.xyz/wc-create-lead", values)
            .then(data => {
              btn_submit.current.classList.remove("loading")
              actions.setSubmitting(false)
              actions.resetForm()
              setShowMessage(true)
              executeScroll()
            })
            .catch(e => {
              alert(e)
            })
        }}
        validate={values => {
          const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
          const errors = {}
          if (!values.name) {
            errors.name = "El nombre es requerido"
          }
          if (!values.email || !emailRegex.test(values.email)) {
            errors.email = "El formato de email ingresado no es correcto"
          }
          if (!values.phone) {
            errors.phone = "El teléfono es requerido"
          }
          return errors
        }}
      >
        {() => (
          <div>
            <Row>
              <Col xs={12} md={6}>
                <h2 className="mb-3">Contactanos</h2>
                <ReactMarkdown className="mb-3 format-markdown">
                  {description}
                </ReactMarkdown>
              </Col>
              <Col xs={12} md={6}>
                <Form method="POST">
                  <div className="form-group">
                    <label htmlFor="name">Nombre y Apellido</label>
                    <Field name="name" className="form-control" />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <Field name="email" className="form-control" />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="phone">Teléfono</label>
                    <Field name="phone" className="form-control" />
                    <ErrorMessage
                      name="phone"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="name">Empresa o Institución</label>
                    <Field name="institution" className="form-control" />
                  </div>
                  <button
                    ref={btn_submit}
                    className="btn btn-primary"
                    type="submit"
                  >
                    Enviar
                    <span
                      className="spinner-border spinner-border-sm ml-2 show-on-loading"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Loading...</span>
                  </button>
                </Form>
              </Col>
            </Row>
          </div>
        )}
      </Formik>
      {showMessage && (
        <div className="my-5">
          <Alert
            variant="success"
            ref={btn_scrollMessage}
            className="text-center"
            onClose={() => setShowMessage(false)}
            dismissible
          >
            <Alert.Heading>¡Su consulta fue enviada con exito!</Alert.Heading>
            <p className="mb-0">
              Nos contactaremos a la brevedad, muchas gracias.
            </p>
          </Alert>
        </div>
      )}
    </>
  )
}

export default CourseForm
